.cafeduran-background {
    background-image: linear-gradient(
      15deg,
      #80383d 100%,
      rgba(44, 44, 125, 0.2)
    );
    color: #0000;
    /*  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06); */
  
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .cafeduran-background {
   
  }

  .cafeduran-topnav {
    float: left;
    border: none;
  
    color: #ff0800;
    text-align: center;
    padding: 20px 20px;
    text-decoration: none;
    font-size: 18px;
    margin: 0;
  }