.topnav {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.left-nav {
  float: right;
  border: none;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  border: none;

  color: #f2f2f2;
  text-align: center;
  padding: 20px 20px;
  text-decoration: none;
  font-size: 18px;
  margin: 0;
}

.topnav a:hover {
  color: white;
  text-decoration: none;
  font-weight: bolder;
}

/* Change the color of links on hover */

/*Black theme for navbar*/

.black-background {
  background-color: rgba(11, 11, 11, 1);
  color: #0000;
  box-shadow: 0px -85px 266px -18px rgba(0, 0, 0, 0.74);
  font-weight: bold;
}

.black-background a {
  color: #fff;
}

.black-background a:hover {
  color: white;
  text-decoration: none;
  font-weight: bolder;
}

.white-background .left-nav a:hover {
  color: #0000;
  text-decoration: none;
  font-weight: bolder;
}

/*White theme for navbar*/
.navbar-toggler span {
  background-color: white;
}
.white-background {
  background-color: white;
  color: #0000;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06);
  border-style: solid;
  border-bottom: rgba(0, 0, 0, 0.06);

  margin: 0;
  padding: 0;
}

.white-background a:hover {
  color: #000000;
  text-decoration: none;
  font-weight: bolder;
}
.white-background a {
  color: #000000;
}

.white-background .left-nav a:hover {
  color: black;
  text-decoration: none;
  font-weight: bolder;
}

.white-background .icon i.fa.fa-bars {
  color: #000000;
}

/*****************************/

/* Add an active class to highlight the current page */
.active {
  height: 100%;
}
.topnav .active a {
  margin: auto;
  padding: 16px 20px;
}

.topnav .active:hover {
  background-color: rgba(4, 5, 93, 1);
}

/*Estilo de imagen del narbar*/
.narvbar-image-container {
  display: inline;
  padding: 0;
  margin: 0;
}
.narvbar-image-container a {
  padding: 0;
  margin: 0;
}
.img-fluid.navbar-image {
  height: 60px;
  border: none;
  margin: 0;
  padding: 0;
  background-color: initial;
}

.topnav .nav-image-brand img {
  height: 60px;
  border: none;
  position: absolute;
  margin: 0px;
  padding: 0px;
  background-color: initial;
}

.narvbar-image-container {
  height: 60px;
  width: 60px;
}

@supports (object-fit: cover) {
  .narvbar-image-container img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}
@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav .left-nav a {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
  .topnav {
    top: 0;
    z-index: 2;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .left-nav {
    display: block;
  }
  .topnav.responsive .left-nav a {
    float: none;
    display: block;
    text-align: right;
  }

  .topnav {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.display-nav-none {
  display: none;
}

/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 10; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 10px;
  left: 25px;
  font-size: 36px;
  margin-right: 50px;
  color: #fff;
}

.sidenav .closebtn a:hover {
  font-weight: bold;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
