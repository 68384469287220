.demo-image-container {
  height: 200px;

}
.demo-image-container a {
  padding: 0;
  margin: 0;
}
.demo-image-container img {
  width: 100%;
  height: auto;
  padding: 1rem;
  margin: 0;
}



@supports (object-fit: cover) {
  .demo-image-container img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

@media screen and (max-width: 600px) {
  .demo-image-container {
    height: 200px;
    overflow: hidden;
  }
}
