.demo-background {
  background-image: linear-gradient(
    15deg,
    #2c2c7d 100%,
    rgba(44, 44, 125, 0.2)
  );
  color: #0000;
  /*  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06); */

  margin: 0;
  padding: 0;
}

.demo-background {
}
