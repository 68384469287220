.tienda-image-container {
  height: 300px;
  overflow: hidden;
}

.tienda-image-container img {
  width: 100%;
  height: auto;
}
@supports (object-fit: cover) {
  .tienda-image-container img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}
