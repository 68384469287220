.container-overlay {
  position: relative;

  cursor: pointer;
}
.container-overlay .overlay-item {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  animation-duration: 2s;
  z-index: 2;
}

@keyframes example {
  from {
    display: none;
  }
  to {
    display: block;
  }
}
