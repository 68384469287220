.positionElement-top{
  position: fixed;
  top:0;
}

.positionElement-bottom{
  position: fixed;
  bottom: 0;
}

.alert-zIndex{
  z-index: 3;
}