.punchcards-image-container {
  height: 150px;
  width: 150px;
  overflow: hidden;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.size-punch-sm {
  height: 100px;
  width: 100px;
}

.size-punch-md {
  height: 150px;
  width: 150px;
}

.punchcards-image-container img {
  width: 100%;
}

.filter-gray {
  filter: invert(0.3);
}

.position-text-punch {
  position: absolute;
  z-index: 4;
  top: 37%;
  left: 40%;
}

@media screen and (max-width: 600px) {
  .punchcards-image-container {
    height: 100px;
    width: 100px;
    overflow: hidden;
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  .punchcards-image-container img {
    width: 100%;
  }

  .position-text-punch {
    position: absolute;
    z-index: 3;
    top: 30%;
    left: 35%;
  }
}
@supports (object-fit: cover) {
  .punchcards-image-container img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
/*
configuracion de imagenes de punccard minatura
*/
