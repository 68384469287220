.punchcard-image-container {
  height: 300px;
  overflow: hidden;
}

.punchcard-image-container img {
  width: 100%;
  height: auto;
}
@supports (object-fit: cover) {
  .punchcard-image-container img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

@media screen and (max-width: 600px) {
  .punchcard-image-container {
    height: 250px;
    overflow: hidden;
  }
}
