.merkadoo-background {
  background-image: linear-gradient(15deg, #e4ffe4 0%, #94fc95 100%);
  color: #0000;
  /*  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06); */

  margin: 0;
  padding: 0;
}

.merkadoo-background a:hover {
  color: #000000;
  text-decoration: none;
  font-weight: bolder;
}
.merkadoo-background a {
  color: #000000;
}
