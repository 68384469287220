@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Roboto san-serif", "Poppins", "san-serif";
}
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", "san-serif";
}

.sweetalert-code h3 {
  font-weight: bold;
  letter-spacing: 2.5px;
  padding: 4px 5px;
  border: rgb(10, 13, 14) 5px double;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.progress-bar-bottom {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  margin: 0;
}

.container-pagination > ul {
  align-items: center;
}
