.main-container-404 {
  width: 100%;
  height: 100%;
}

.container404 {
  width: 700px;
  margin: auto;
  text-align: center;
}
.image404 {
  margin: auto;
  width: inherit;
}

.notfound h2 {
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .container404 {
    width: 300px;
    margin: auto;
  }
}

@media only screen and (max-width: 480px) {
  .notfound h2 {
    font-size: 16px;
  }
  .container404 {
    width: 300px;
    margin: auto;
  }
}
